import React from 'react'
import { Company, CompanyConfig, CompanyStatus } from '../companies/models/company'
import { Button, Typography, styled } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { SectionFormDisplay } from './config/SectionFormDisplay'
import { EditCompanyConfigDialog } from './config/EditCompanyConfigDialog'
import ConfirmDialogV2 from 'components/general/dialogs/confirm-dialog-v2'
import { useChangeCompanyStatusMutation } from 'redux/apis/companies-api'
import { QueryStatusView } from 'components/general/query-status.view'
import { Config } from 'redux/config'

type Section = {
    title: string
    key: keyof CompanyConfig
}

const isApiTokensFeatureOn = Config.enableCustomerApi

const SECTIONS: Section[] = isApiTokensFeatureOn
    ? [
          // t('TEXT_COMPANY_CONFIG_PERMISSIONS_TITLE')
          { title: 'TEXT_COMPANY_CONFIG_PERMISSIONS_TITLE', key: 'permissions' },
          // t('TEXT_COMPANY_CONFIG_API_ACCESS_TITLE')
          { title: 'TEXT_COMPANY_CONFIG_API_ACCESS_TITLE', key: 'apiAccess' },
      ]
    : [
          // t('TEXT_COMPANY_CONFIG_PERMISSIONS_TITLE')
          { title: 'TEXT_COMPANY_CONFIG_PERMISSIONS_TITLE', key: 'permissions' },
      ]

type CompanyAdminConfigViewProps = {
    company: Company
}

export const CompanyAdminConfigView: React.FC<CompanyAdminConfigViewProps> = ({
    company: { config, id, name, status },
}) => {
    const { t } = useTranslation()
    const [changeStatus, { isLoading: isChangingStatus, error, isError }] = useChangeCompanyStatusMutation()

    const [selectedSectionKey, setSelectedSectionKey] = React.useState<keyof CompanyConfig | null>(null)
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false)

    const isActive = status === CompanyStatus.Active

    const handleCompanyDeactivateOrActivate = async () => {
        try {
            await changeStatus({
                id,
                status: isActive ? CompanyStatus.Inactive : CompanyStatus.Active,
            }).unwrap()
            setIsConfirmDialogOpen(false)
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <>
            <div>
                <ButtonContainer>
                    <Button
                        variant="contained"
                        color="accent"
                        sx={{ minWidth: 120 }}
                        onClick={() => setIsConfirmDialogOpen(true)}
                    >
                        {isActive
                            ? t('TEXT_COMPANY_CONFIG_DEACTIVATE_COMPANY')
                            : t('TEXT_COMPANY_CONFIG_ACTIVATE_COMPANY')}
                    </Button>
                </ButtonContainer>
                <div>
                    {SECTIONS.map(({ key, title }) => (
                        <Section key={key}>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                {t(title)}
                            </Typography>

                            <SectionFormDisplay form={config[key]} onEnableEdit={() => setSelectedSectionKey(key)} />
                        </Section>
                    ))}
                </div>
            </div>
            {selectedSectionKey && (
                <EditCompanyConfigDialog
                    onClose={() => setSelectedSectionKey(null)}
                    configSectionKey={selectedSectionKey}
                    config={config}
                    companyId={id}
                />
            )}
            <ConfirmDialogV2
                handleClose={() => setIsConfirmDialogOpen(false)}
                open={isConfirmDialogOpen}
                onConfirm={handleCompanyDeactivateOrActivate}
                content={
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        {isActive ? (
                            <Trans
                                i18nKey="TEXT_CONFIRM_COMPANY_DEACTIVATION"
                                values={{
                                    companyName: name || '',
                                }}
                                defaults="Do you want to deactivate <strong>{{companyName}}</strong> company? <br /> All its tokens will be deactivated also"
                            />
                        ) : (
                            <Trans
                                i18nKey="TEXT_CONFIRM_COMPANY_ACTIVATION"
                                values={{
                                    companyName: name || '',
                                }}
                                defaults="Do you want to reactivate <strong>{{companyName}}</strong> company? <br /> All its tokens will be activated back also"
                            />
                        )}
                        <QueryStatusView error={error} isError={isError} />
                    </Typography>
                }
                isLoading={isChangingStatus}
            />
        </>
    )
}

const Section = styled('div')(({ theme }) => ({
    padding: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.accent.main}`,

    '&:last-child': {
        borderBottom: 'none',
    },
}))

const ButtonContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(4, 2),
    borderBottom: `1px solid ${theme.palette.accent.main}`,
}))

// const SectionsContainer = styled('div')
