import React from 'react'
import { Box, Grid } from '@mui/material'
import { PropertyOverview } from 'components/property/shared/PropertyOverview'
import {
    flattenAndMapHouseUnits,
    getAllOwners,
    getBestHousingUnit,
    getMainProperty,
    getMainPropertyCadastreNumber,
    hasHousingUnits,
} from 'utils/cadastre-utils'
import { CartProperty, PropertyResponseItem } from 'components/search/models/property-search-response'
import { PropertyProductsList } from 'components/property/products/PropertyProductsList'
import { useTranslation } from 'react-i18next'
import { GeneralPropertyUnitTable } from 'components/property/shared/GeneralPropertyUnitTable'

type SectionedPropertyChildViewProps = {
    units: PropertyResponseItem[]
}

export const SectionedPropertyChildView: React.FC<SectionedPropertyChildViewProps> = ({ units }) => {
    const { t } = useTranslation()

    // for sectioned properties it can not find main property so we default to the first one
    const mainProperty = getMainProperty(units) || units[0]
    const sanitizedMainPropertyCadastreNr = getMainPropertyCadastreNumber(mainProperty?.cadastreNumber || null)
    const owners = getAllOwners(units)

    const allHouseUnits = flattenAndMapHouseUnits(units)
    const hu = getBestHousingUnit(mainProperty.housingUnits)

    const property: CartProperty = {
        cadastreId: hu.cadastreId,
        cadastreNumber: sanitizedMainPropertyCadastreNr,
        hasHousingUnits: hasHousingUnits(units),
    }

    return (
        <>
            <Grid item xs={12}>
                <PropertyOverview
                    data={units}
                    isPropertySectioned={false}
                    sanitizedMainPropertyCadastreNr={sanitizedMainPropertyCadastreNr}
                    mainProperty={mainProperty}
                    allOwners={owners}
                />
                <div>
                    <Box mt={2}>
                        <PropertyProductsList property={property} />
                    </Box>
                    <Box mt={2}>
                        <GeneralPropertyUnitTable
                            units={allHouseUnits}
                            title={t('HOUSING_UNITS', 'Bruksenheter ({{huCount}})', {
                                huCount: allHouseUnits.length,
                            })}
                        />
                    </Box>
                </div>
            </Grid>
            <Grid item xs={12}></Grid>
        </>
    )
}
