/** @jsxImportSource @emotion/react */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'

export const propertyStyles = {
    mainProduct: css`
        padding: 16px;
    `,
    propertyLineHeader: css`
        cursor: pointer;
    `,
    propertyLine: css`
        background: #fff;
        display: flex;
        align-items: center;
        width: 100%;
    `,
    column1: {
        width: '15%',
    },
    column2: {
        width: '20%',
    },
    column3: {
        width: '20%',
    },
    column4: {
        width: '10%',
    },
    column5: {
        width: '15%',
        flexGrow: 1,
    },
    column6: {
        width: '15%',
    },
    column7: {
        width: '5%',
    },

    gridItem: css`
        padding: 10px;
    `,
    expandedContent: css`
        background: white;
        border-top: 2px solid #f8f8f8;
        margin-bottom: 10px;
    `,
    expandedProperty: css`
        margin-top: 10px;
        box-shadow: 1px 1px 4px 1px rgba(145, 145, 145, 0.47);
        -webkit-box-shadow: 1px 1px 4px 1px rgba(145, 145, 145, 0.47);
        -moz-box-shadow: 1px 1px 4px 1px rgba(145, 145, 145, 0.47);
    `,
    contractedProperty: css`
        margin-top: 2px;
    `,
    sectionTitle: css`
        background: #fff;
        padding: 22px 10px;
        font-size: 20px;
    `,
    arrowItem: css`
        padding: 10px;
        margin-right: 16px;
    `,
    searchBar: css`
        width: 100%;
        max-width: 600px;
        padding: 10px;
    `,
    searchBarWrapper: css`
        background: #fff;
    `,
}

export const PropertyHeader: React.FC<{ isMain?: boolean }> = ({ isMain = false }) => {
    const { t } = useTranslation()
    const styles = propertyStyles
    return (
        <div css={styles.propertyLine}>
            <div css={[styles.column1, styles.gridItem]}>{t('TEXT_CADASTRE_NR', 'Matrikkelnummer')}</div>
            <div css={[styles.column2, styles.gridItem]}>{!isMain && t('TEXT_OWNER', 'Eier')}</div>
            <div css={[styles.column3, styles.gridItem]}>{!isMain && t('TEXT_ADDRESS', 'Adresse')}</div>
            <div css={[styles.column4, styles.gridItem]}>{!isMain && t('TEXT_POStAL_CODE', 'Postnummer')}</div>
            <div css={[styles.column5, styles.gridItem]}>{!isMain && t('TEXT_BUILDING_TYPE', 'Bygningstype')}</div>
            <div css={[styles.column6, styles.gridItem]}>
                {!isMain && t('TEXT_HOUSING_UNIT_NR', 'Bruksenhetsnummer')}
            </div>
            <div css={[styles.column7, styles.gridItem]}></div>
        </div>
    )
}

export const PropertyHeaderV2: React.FC<{ showOwnerColumn: boolean }> = ({ showOwnerColumn }) => {
    const { t } = useTranslation()
    const styles = propertyStyles
    return (
        <div css={styles.propertyLine}>
            <div css={[styles.column1, styles.gridItem]}>{t('TEXT_CADASTRE_NR', 'Matrikkelnummer')}</div>
            {showOwnerColumn && <div css={[styles.column2, styles.gridItem]}>{t('TEXT_OWNER', 'Eier')}</div>}
            <div css={[styles.column3, styles.gridItem]}>{t('TEXT_ADDRESS', 'Adresse')}</div>
            <div css={[styles.column4, styles.gridItem]}>{t('TEXT_POStAL_CODE', 'Postnummer')}</div>
            <div css={[styles.column5, styles.gridItem]}>{t('TEXT_BUILDING_TYPE', 'Bygningstype')}</div>
            <div css={[styles.column6, styles.gridItem]}>{t('TEXT_HOUSING_UNIT_NR', 'Bruksenhetsnummer')}</div>
            <div css={[styles.column7, styles.gridItem]}></div>
        </div>
    )
}
