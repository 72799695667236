/** @jsxImportSource @emotion/react */
import React from 'react'
import { formatCadastreNumber, getBestHousingUnit } from 'utils/cadastre-utils'
import { PropertyHeaderV2, propertyStyles as styles } from '../property-helper'
import { PropertyResponseItem } from 'components/search/models/property-search-response'
import { PropertyTableFilters } from './PropertyTableFilters'
import { usePropertyTable } from '../usePropertyTable'

type GeneralPropertyUnitTableProps = {
    units: PropertyResponseItem[]
    title: string
}

export const GeneralPropertyUnitTable: React.FC<GeneralPropertyUnitTableProps> = ({ units, title }) => {
    const { addressFilter, filteredUnits, onRemoveAddressFilter, onSearchUnits } = usePropertyTable(units)

    const rowContainerStyles = [styles.propertyLine]

    return (
        <div>
            <PropertyTableFilters
                title={title}
                onDeleteQueryAddress={onRemoveAddressFilter}
                onSearch={(query) => onSearchUnits(query)}
                queryAddress={addressFilter}
            />
            <div>
                <PropertyHeaderV2 showOwnerColumn={false} />
                {filteredUnits.map((unit, index) => {
                    const hu = getBestHousingUnit(unit.housingUnits)
                    return (
                        <div key={index} css={styles.contractedProperty}>
                            <div css={rowContainerStyles}>
                                <div css={[styles.column1, styles.gridItem]}>
                                    {formatCadastreNumber(unit.cadastreNumber)}
                                </div>
                                <div css={[styles.column3, styles.gridItem]}>{hu.address.addressText}</div>
                                <div css={[styles.column4, styles.gridItem]}>{`${hu.address.postalCode || ''} ${
                                    hu.address.postalCodeName || ''
                                }`}</div>
                                <div css={[styles.column5, styles.gridItem]}>{hu.buildingCodeName}</div>
                                <div css={[styles.column6, styles.gridItem]}>{hu.address.residentialNumber}</div>
                                <div css={[styles.column7, styles.gridItem]} />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
