/** @jsxImportSource @emotion/react */
import React from 'react'
import { formatCadastreNumber, getAllOwners, getBestHousingUnit } from 'utils/cadastre-utils'
import { PropertyHeaderV2, propertyStyles as styles } from '../../property-helper'
import { PropertyResponseItem, PropertyType } from 'components/search/models/property-search-response'
import { MultiStringViewer } from 'components/general/multi-viewer/multi-string-viewer'
import { KeyboardArrowRight } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { usePropertyTable } from 'components/property/usePropertyTable'
import { PropertyTableFilters } from 'components/property/shared/PropertyTableFilters'

type SectionedParentPropertyUnitTableProps = {
    units: PropertyResponseItem[]
    title: string
    type: PropertyType
}

export const SectionedParentPropertyUnitTable: React.FC<SectionedParentPropertyUnitTableProps> = ({
    units,
    title,
    type,
}) => {
    const navigate = useNavigate()

    const { addressFilter, filteredUnits, onRemoveAddressFilter, onSearchUnits } = usePropertyTable(units)

    const rowContainerStyles = [styles.propertyLine, styles.propertyLineHeader]

    const handleNavigateToPropertyChild = (cadastreNr: string) => navigate({ search: `?cadastreNumber=${cadastreNr}` })

    const handleNavigateWithKey = (event: React.KeyboardEvent<HTMLDivElement>, cadastreNr: string) => {
        if (event.key === 'Enter') {
            handleNavigateToPropertyChild(cadastreNr)
        }
    }

    return (
        <div>
            <PropertyTableFilters
                title={title}
                onDeleteQueryAddress={onRemoveAddressFilter}
                onSearch={(query) => onSearchUnits(query)}
                queryAddress={addressFilter}
            />
            <div>
                <PropertyHeaderV2 showOwnerColumn={type !== 'MainProperty'} />
                {filteredUnits.map((unit, index) => {
                    const hu = getBestHousingUnit(unit.housingUnits)
                    const owners = getAllOwners([unit])

                    return (
                        <div
                            key={index}
                            css={styles.contractedProperty}
                            tabIndex={0}
                            onKeyDown={(event) => handleNavigateWithKey(event, unit.cadastreNumber)}
                            onClick={() => handleNavigateToPropertyChild(unit.cadastreNumber)}
                        >
                            <div css={rowContainerStyles}>
                                <div css={[styles.column1, styles.gridItem]}>
                                    {formatCadastreNumber(unit.cadastreNumber)}
                                </div>
                                <div css={[styles.column2, styles.gridItem]}>
                                    <MultiStringViewer owners items={owners} />
                                </div>
                                <div css={[styles.column3, styles.gridItem]}>{hu.address.addressText}</div>
                                <div css={[styles.column4, styles.gridItem]}>{`${hu.address.postalCode || ''} ${
                                    hu.address.postalCodeName || ''
                                }`}</div>
                                <div css={[styles.column5, styles.gridItem]}>{hu.buildingCodeName}</div>
                                <div css={[styles.column6, styles.gridItem]}>{hu.address.residentialNumber}</div>
                                <div css={[styles.column7, styles.gridItem]}>
                                    <KeyboardArrowRight />
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
